<template>
  <div>
    <div class="flex flex-wrap-reverse items-center">
      <div
        class="p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-between text-lg font-medium text-base text-primary border border-solid border-primary"
        @click="addNew"
      >
        <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
        <span class="ml-2 text-base text-primary">{{$t('AddNew')}}</span>
      </div>
    </div>
    <Grid
      :key="key"
      :service="service"
      route_name="email_templates"
      :column_formats="{
        'type.name': (val) => $t(`email_template_types.${val}`),
        'created_at': (val) => $utils.format.date(val, true),
        'active': (val) => val ? 'Ativo' : 'Inativo'
      }"
      :css_formats="{
        'active': (val) => val ? 'text-success' : 'text-danger'
      }"
      :hide_searchbar="true"
    />
  </div>
</template>

<script>
import EmailTemplateService from '@/services/api/EmailTemplateService'

export default {
  data: () => ({
    selected: [],
    service: null,
    key: 0
  }),
  methods: {
    addNew() {
      this.$router.push('/email_templates/create')
    }
  },
  beforeMount() {
    this.service = EmailTemplateService.build(this.$vs)
  }
}
</script>
