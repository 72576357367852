var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "flex flex-wrap-reverse items-center" }, [
        _c(
          "div",
          {
            staticClass:
              "p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-between text-lg font-medium text-base text-primary border border-solid border-primary",
            on: { click: _vm.addNew },
          },
          [
            _c("feather-icon", {
              attrs: { icon: "PlusIcon", svgClasses: "h-4 w-4" },
            }),
            _c("span", { staticClass: "ml-2 text-base text-primary" }, [
              _vm._v(_vm._s(_vm.$t("AddNew"))),
            ]),
          ],
          1
        ),
      ]),
      _c("Grid", {
        key: _vm.key,
        attrs: {
          service: _vm.service,
          route_name: "email_templates",
          column_formats: {
            "type.name": function (val) {
              return _vm.$t("email_template_types." + val)
            },
            created_at: function (val) {
              return _vm.$utils.format.date(val, true)
            },
            active: function (val) {
              return val ? "Ativo" : "Inativo"
            },
          },
          css_formats: {
            active: function (val) {
              return val ? "text-success" : "text-danger"
            },
          },
          hide_searchbar: true,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }